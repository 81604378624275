import { lazy, useEffect } from 'react';
import VersionedComponent from '../../../shared/VersionedUi/VersionedComponent';
import classnames from 'classnames';
import { NavbarProvider } from './navbarContext';
import useNavbarUiVersion from '../../../hooks/useNavbarUiVersion';

if (process.env.NODE_ENV === 'development') {
  require('./styles/Debug.scss');
}

const NavbarV1 = lazy(() => import('../../Navbar/Navbar'));
const NavbarV2 = lazy(() => import(/* webpackChunkName: "navbarV2" */ './NavbarV2'));

const NavbarVersioned = (props: {
  countryRedirects?: { CountryCode: string; Prefix: string; LanguageCode: string }[];
  defaultValues?: any;
}) => {
  const { version } = useNavbarUiVersion();

  return (
    <div
      className={classnames(
        {
          'new-ui': version === 'v2'
        },
        'navbar-versioned'
      )}>
      <NavbarProvider>
        <VersionedComponent v1={NavbarV1} v2={NavbarV2} props={props} isMenu={true} />
      </NavbarProvider>
    </div>
  );
};

export default NavbarVersioned;
