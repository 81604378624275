import { ComponentType, FC, Fragment, Suspense, useMemo } from 'react';
import useUiVersion from '../../hooks/useUiVersion';
import useNavbarUiVersion from '../../hooks/useNavbarUiVersion';

type ConditionalProps<
  TProps,
  TComponentProps = Record<string, never>
> = TComponentProps extends Record<string, never>
  ? TProps
  : TProps & {
      props: TComponentProps;
    };

type VersionedComponentProps<TComponentProps = Record<string, never>> = {
  v1: ComponentType<TComponentProps> | FC<TComponentProps>;
  v2?: ComponentType<TComponentProps> | FC<TComponentProps>;
  stylesOverride?: () => JSX.Element;
  props?: TComponentProps;
  isMenu?: boolean;
};

const VersionedComponent = <TComponentProps = Record<string, never>,>({
  v1: V1,
  v2: V2,
  stylesOverride: STYLES,
  props,
  isMenu = false
}: ConditionalProps<VersionedComponentProps<TComponentProps>, TComponentProps>) => {
  const { version } = isMenu ? useNavbarUiVersion() : useUiVersion();

  const Component = useMemo(() => (version === 'v2' ? V2 ?? V1 : V1), [V1, V2, version]);
  const Overrides = useMemo(() => (version === 'v2' ? STYLES ?? Fragment : Fragment), [version]);

  return (
    <>
      <Suspense fallback={<></>}>
        <Overrides />
        <Component {...props} />
      </Suspense>
    </>
  );
};

export default VersionedComponent;
