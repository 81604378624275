import createFastContext from '../../../helpers/createFastContext';
import { IDMConfig, UserLogin } from '../../../types/dto/UserLogin';

type NavbarContext = {
  user: any;
  logins: UserLogin[];
  idmConfig?: IDMConfig;
};

export const { Provider: NavbarProvider, useStore: useNavbarStore } =
  createFastContext<NavbarContext>({
    user: null,
    logins: []
  });
