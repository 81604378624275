import { useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';

const isNewUIAtt = document.querySelector('html')?.getAttribute('data-new-ui') === 'True';

const isNewFooterMenu =
  document.querySelector('html')?.getAttribute('data-new-footer-menu') === 'True';

let isNewUI = false;

if (isNewFooterMenu || isNewUIAtt) isNewUI = true;

type Version = 'v1' | 'v2';

const useNavbarUiVersion = () => {
  const [cookies, setCookie] = useCookies(['ui-version']);

  const version: Version = useMemo(() => {
    if (!cookies['ui-version']) {
      return isNewUI ? 'v2' : 'v1';
    } else {
      return cookies['ui-version'];
    }
  }, [cookies['ui-version']]);

  const switchVersion = useCallback(() => {
    setCookie('ui-version', version == 'v2' ? 'v1' : 'v2');
  }, [version, setCookie]);

  return { version, switchVersion };
};

export default useNavbarUiVersion;
